var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader-dialog',{attrs:{"is-dialog-visible":_vm.loaderDialog}}),_c('v-card',[_c('v-alert',{attrs:{"color":_vm.error ? 'error' : 'success',"dismissible":"","text":"","transition":"slide-y-transition"},model:{value:(_vm.isAlertVisible),callback:function ($$v) {_vm.isAlertVisible=$$v},expression:"isAlertVisible"}},[_vm._v(" "+_vm._s(_vm.reponseMsg)+" ")]),(_vm.license_path)?_c('v-img',{attrs:{"src":_vm.license_path,"height":"250","contain":""}}):_c('v-img',{attrs:{"src":require("@/assets/images/png/mboa_cov_logo.png"),"height":"250","contain":""}}),_c('v-card-title',[_vm._v(" Permis de conduire ")]),_c('v-card-text',[_c('v-list',[_c('v-list-item',{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Validé le:")]),_c('span',{staticClass:"text--secondary font-weight-bold"},[_vm._v(_vm._s(_vm.validated_at ? _vm.formattingDate(_vm.validated_at) : null))])])],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary mr-2",attrs:{"icon":"","elevation":"5"},on:{"click":function($event){_vm.dialogConfirm = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")])],1)]}}])},[_c('span',[_vm._v("Valider")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"error mr-2",attrs:{"icon":"","elevation":"5"},on:{"click":function($event){_vm.rejectConfirm = true}}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])},[_c('span',[_vm._v("Rejeter")])]),_c('v-tooltip',{attrs:{"right":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey",attrs:{"icon":"","elevation":"5","outlined":""},on:{"click":_vm.openDialog}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnifyPlusOutline)+" ")])],1)]}}])},[_c('span',[_vm._v("Agrandir")])])],1)],1),_c('license-img',{attrs:{"license_path":_vm.license_path,"dialog":_vm.dialog},on:{"clickout":_vm.closeDialog}}),_c('confirm-dialog',{attrs:{"message":_vm.message,"dialog-delete":_vm.dialogConfirm},on:{"clickout":function($event){_vm.dialogConfirm = false},"submitted":_vm.validateLicense}}),_c('reject-license-form',{attrs:{"dialog":_vm.rejectConfirm,"user":_vm.user},on:{"clickout":function($event){_vm.rejectConfirm = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }