<template>
  <div>
    <v-card class="mb-8">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <city-input
              v-model="$store.state.userRoad.field.city"
              :label="'Rechercher par la ville de départ'"
              :placeholder="'Rechercher par la ville de départ'"
              :rules="rules.city"
              @change="filterItems"
              @getCity="getStartOmsId"
            ></city-input>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <city-input
              v-model="$store.state.userRoad.field.destination"
              :label="'Rechercher par la destination'"
              :placeholder="'Rechercher par la destination'"
              :rules="rules.destination"
              @change="filterItems"
              @getCity="getEndOmsId"
            ></city-input>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.userRoad.field.car"
              label="Rechercher par voiture"
              outlined
              dense
              placeholder="Rechercher par voiture"
              hide-details="auto"
              :rules="rules.car"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.userRoad.field.min_amount"
              label="Filtrer par montant minimum"
              outlined
              dense
              placeholder="Filtrer par montant minimum"
              hide-details="auto"
              :rules="rules.min_amount"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.userRoad.field.max_amount"
              label="Filtrer par montant maximum"
              outlined
              dense
              placeholder="Filtrer par montant maximum"
              hide-details="auto"
              :rules="rules.max_amount"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              ref="menuref"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="$store.state.userRoad.field.start_date"
                  label="Filtrer par date de départ"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="rules.start_date"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="$store.state.userRoad.field.start_date"
                min="1950-01-01"
                color="primary"
                @change="save"
                @input="filterItems"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['userRoad/roads']"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.start_date`]="{item}">
          {{ item.start_date !== null ? formattingDate(`${item.start_date}`) : null }}
        </template>

        <template #[`item.car`]="{item}">
          {{ item.car !== null ? `${item.car.marque} ${item.car.model} ${item.car.release_year}` : null }}
        </template>
        <template #[`item.global_amount`]="{item}">
          <v-chip
            :color="new Date(item.start_date) > new Date() ? 'primary' : 'error'"
            small
          >
            {{ formatAmount(item.global_amount) }}
          </v-chip>
        </template>
        <template #[`item.amount`]="{item}">
          <v-chip
            color="info"
            small
          >
            {{ formatAmount(item.amount) }}
          </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :remove="false"
            :edit="false"
            :lock="false"
            :status="false"
            @view="openItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.userRoad.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getRoadsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getRoads"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Voulez-vous bloquer cet utilisateur?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Non
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            Oui
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { onMounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline,
  mdiSquareEditOutline,
} from '@mdi/js'
import CityInput from '@core/components/autocomplete/CityInput'
import { formatDate } from '@core/date/dateFunctions'
import store from '@/store'
import router from '@/router'
import {watch} from "@vue/composition-api";
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";

export default {
  name: 'UserRoadList',
  components: {ButtonsGroup, CityInput, LoaderDialog },

  props: {
    isRefreshed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const message = ref(null)
    const error = ref(false)
    const isAlertVisible = ref(false)
    const rules = {
      max_amount: [true],
      min_amount: [true],
      nb_passagers: [true],
      start_date: [true],
      city: [true],
      destination: [true],
      car: [true],
    }

    const formattingDate = date => formatDate(date)

    const getStartOmsId = event => {
      store.state.userRoad.field.city = event ? event.toString() : null
      getRoadsByFields()
    }

    const getEndOmsId = event => {
      store.state.userRoad.field.destination = event ? event.toString() : null
      getRoadsByFields()
    }

    const deleteItemConfirm = () => {
      closeDelete()
    }

    const filterItems = () => {
      setTimeout(() => {
        getRoadsByFields()
      }, 1500)
    }

    const openItem = item => {
      router.push({ name: 'showroad', params: { id: item.id } })
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const getRoads = (page = 1) => {
      isDialogVisible.value = true
      store.state.userRoad.field.user_id = store.state.user.user.id
      store.dispatch('userRoad/getRoadsList', { page, field: store.state.userRoad.field }).then(() => {
        currentPage.value = store.getters['userRoad/current_page']
        lastPage.value = store.getters['userRoad/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

         Object.keys(rules).forEach(key => {
          rules[key] = [true]
        })
        message.value = ''
      }).catch(err => {
        message.value = err.response.data.message
        if (err.response.data.errors !== undefined) {
          rules.max_amount = err.response.data.errors.max_amount !== undefined ? err.response.data.errors.max_amount : [true]
          rules.min_amount = err.response.data.errors.min_amount !== undefined ? err.response.data.errors.min_amount : [true]
          rules.nb_passagers = err.response.data.errors.nb_passagers !== undefined ? err.response.data.errors.nb_passagers : [true]
          rules.start_date = err.response.data.errors.start_date !== undefined ? err.response.data.errors.start_date : [true]
          rules.car = err.response.data.errors.car !== undefined ? err.response.data.errors.car : [true]
          rules.city = err.response.data.errors.city !== undefined ? err.response.data.errors.city : [true]
          rules.destination = err.response.data.errors.destination !== undefined ? err.response.data.errors.destination : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const getRoadsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.userRoad.field.paginate = per_page
        store.state.userRoad.field.user_id = store.state.user.user.id
        itemsPerPage.value = parseInt(per_page, 10)
        store.dispatch('userRoad/getRoadsList', {
          page: currentPage.value,
          field: store.state.userRoad.field,
        }).then(() => {
          currentPage.value = store.getters['userRoad/current_page']
          lastPage.value = store.getters['userRoad/last_page']
          isDialogVisible.value = false
          isAlertVisible.value = false
          Object.keys(rules).forEach(key => {
            rules[key] = [true]
          })
          message.value = ""
        }).catch(err => {
          message.value = err.response.data.message
          if (err.response.data.errors !== undefined) {
            rules.max_amount = err.response.data.errors.max_amount !== undefined ? err.response.data.errors.max_amount : [true]
            rules.min_amount = err.response.data.errors.min_amount !== undefined ? err.response.data.errors.min_amount : [true]
            rules.nb_passagers = err.response.data.errors.nb_passagers !== undefined ? err.response.data.errors.nb_passagers : [true]
            rules.start_date = err.response.data.errors.start_date !== undefined ? err.response.data.errors.start_date : [true]
            rules.car = err.response.data.errors.car !== undefined ? err.response.data.errors.car : [true]
            rules.city = err.response.data.errors.city !== undefined ? err.response.data.errors.city : [true]
            rules.destination = err.response.data.errors.destination !== undefined ? err.response.data.errors.destination : [true]
          }
          error.value = true
          isAlertVisible.value = true
        })
      }
    }

    const getRoadsByFields = () => {
      isDialogVisible.value = true
      store.state.userRoad.field.user_id = store.state.user.user.id
      store.dispatch('userRoad/getRoadsList', {
        page: currentPage.value,
        field: store.state.userRoad.field,
      }).then(() => {
        currentPage.value = store.getters['userRoad/current_page']
        lastPage.value = store.getters['userRoad/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        message.value = ""
         Object.keys(rules).forEach(key => {
          rules[key] = [true]
        })
      }).catch(err => {
        message.value = err.response.data.message
        if (err.response.data.errors !== undefined) {
          rules.max_amount = err.response.data.errors.max_amount !== undefined ? err.response.data.errors.max_amount : [true]
          rules.min_amount = err.response.data.errors.min_amount !== undefined ? err.response.data.errors.min_amount : [true]
          rules.nb_passagers = err.response.data.errors.nb_passagers !== undefined ? err.response.data.errors.nb_passagers : [true]
          rules.start_date = err.response.data.errors.start_date !== undefined ? err.response.data.errors.start_date : [true]
          rules.car = err.response.data.errors.car !== undefined ? err.response.data.errors.car : [true]
          rules.city = err.response.data.errors.city !== undefined ? err.response.data.errors.city : [true]
          rules.destination = err.response.data.errors.destination !== undefined ? err.response.data.errors.destination : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    watch(() => props.isRefreshed, (val1, val2) => {
      if (val1 === true && val2 === false) getRoads()
    })

    onMounted(() => {
      getRoads(store.getters['userRoad/current_page'])
    })

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    return {
      formatAmount,
      getRoadsByFields,
      getRoadsPerPage,
      getRoads,
      filterItems,
      openItem,
      closeDelete,
      getEndOmsId,
      deleteItemConfirm,
      formattingDate,
      getStartOmsId,
      currentPage,
      lastPage,
      dialogDelete,
      isDialogVisible,
      message,
      error,
      isAlertVisible,
      rules,
      save,
      date,
      menu,
      picker,
      menuref,
      selectOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value: false },
        { text: 'Tous', value: null },
      ],
      headers: [
        { text: 'DEPART', value: 'city.name' },
        { text: 'DESTINATION', value: 'destination.name' },
        { text: 'PRIX ', value: 'global_amount' },
        { text: 'PRIX HORS FRAIS', value: 'amount' },
        { text: 'VOITURE', value: 'car.marque' },
        { text: 'NOMBRE DE PASSAGERS', value: 'nb_passagers' },
        { text: 'HEURE', value: 'start_date' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.userRoad.field.paginate, 10),
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
