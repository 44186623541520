<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">Donner le motif {{ user !== null && user.name ? user.name : "" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    v-model="userForm.reject_reason"
                    rows="2"
                    label="Motif"
                    placeholder="Ecrire en fonction de la langue de l'utilisateur"
                    :rules="[rules.reject_reason]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              Rejeter
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api/dist/vue-composition-api'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import store from '@/store'

export default {
  name: 'RejectLicenseForm',

  emits: ['clickout', 'submitted', 'error'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: null,
    doc: null,
  },

  data() {
    return {
      reason: null,
    }
  },

  setup(props) {
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const userForm = {
      reject_reason: null,
    }

    const rules = {
      reject_reason: true,
    }

    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      message.value = ''
      rules.reason_required = true
      userForm.reject_reason = ''
      instance.emit('clickout')
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      instance.emit('submitted')
      store.dispatch('user/rejectLicenseDriver', { user: props.user, field: userForm, doc: props.doc }).then(response => {
        message.value = response.message
        isDialogVisible.value = false
        notify(true, true, response.message)
        close()
      }).catch(error => {
        form.value.validate()
        instance.emit('error')
        message.value = error.response.data.message
        errors.value = error.response.data.errors
        if (error.response.data.errors !== undefined) {
          rules.reject_reason = error.response.data.errors.reject_reason !== undefined ? error.response.data.errors.reject_reason[0] : true
        }
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
    }

    return {
      userForm,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
