<template>
  <div >
    <loader-dialog :is-dialog-visible="isDialogVisible2" />
    <v-card class="mb-8">
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="$store.state.device.field.agent"
              label="Rechercher par le nom de l'appareil"
              outlined
              dense
              placeholder="Rechercher par le nom de l'appareil"
              hide-details
              clearable
              @input="filterElts"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="$store.state.device.field.is_active"
              :items="isActive"
              label="Actif?"
              outlined
              dense
              @input="filterElts"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['device/devices']"
        :items-per-page="$store.getters['device/per_page']"
        :page.sync="$store.getters['device/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.is_active`]="{item}">
          <v-chip
            label
            small
            :color="item.is_active ? 'primary' : 'error'"
          >
            {{ item.is_active ? "OUI" : "NON" }}
          </v-chip>
        </template>
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>
        <template #[`item.deleted_at`]="{item}">
          {{ item.deleted_at ? formattingDate(`${item.deleted_at}`) : null }}
        </template>
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :remove="true"
            :edit="false"
            :lock="false"
            :status="false"
            @view="showToken(item.key)"
            @remove="removeDevice"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.device.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getEltsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getElts"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <device-token-dialog
      :token="contentToken"
      :dialog-content="showDialogContent"
      @clickout="showDialogContent = false"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import CarDetailsDialog from '@core/components/user/CarDetailsDialog'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { mapGetters, mapState } from 'vuex'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import { ref, onMounted, computed } from '@vue/composition-api'
import DeviceTokenDialog from '@core/components/dialogs/DeviceTokenDialog'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'UserDevicesList',

  components: {
    ButtonsGroup, DeviceTokenDialog, CarDetailsDialog, LoaderDialog, ConfirmDialog,
  },

  model: {
    prop: 'user_id',
    event: 'update:user_id',
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    user_id: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const currentPage = ref(null)
    const lastPage = ref(null)
    const contentToken = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const isDialogVisible2 = ref(false)
    const showDialogContent = ref(false)
    const dialog = ref(false)
    const messageDialog = ref('')
    const item = ref({})
    const refreshComponent = ref(0)

    const formattingDate = date => formatDate(date)

    const userId = computed({
      get: () => props.user_id,
      set: value => emit('update:user_id', value),
    })

    // eslint-disable-next-line no-unused-vars
    const openItem = item => {
      isDialogVisible2.value = true
      store.dispatch('device/getDeviceById', item).then(() => {
        isDialogVisible2.value = false
      })
      dialog.value = true
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const getElts = (page = 1) => {
      setTimeout(() => {
        isDialogVisible.value = true

        store.state.device.field.user_id = userId.value
        store.dispatch('device/getDevicesList', { page, field: store.state.device.field }).then(() => {
          currentPage.value = store.getters['device/current_page']
          lastPage.value = store.getters['device/last_page']
          isDialogVisible.value = false
          refreshComponent.value += 1
          console.log(store.getters['device/devices'])
        })
      }, 1000)
    }

    const filterElts = () => {
      setTimeout(() => {
        getElts(1)
      }, 1000)
    }

    // eslint-disable-next-line camelcase
    const getEltsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.device.field.paginate = per_page
        store.state.device.field.user_id = props.user_id
        store.dispatch('device/getDevicesList', {
          page: store.getters['device/current_page'],
          field: store.state.device.field,
        }).then(() => {
          currentPage.value = store.getters['device/current_page']
          lastPage.value = store.getters['device/last_page']
          isDialogVisible.value = false
        })
      }
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const showToken = val => {
      contentToken.value = val
      showDialogContent.value = true
    }

    onMounted(() => {
      getElts(store.getters['device/current_page'])
    })

    const removeDevice = object => {
      messageDialog.value = `Voulez-vous supprimer l'appareil ${object.agent}?`
      item.value = object
      dialogDelete.value = true
      console.log(`event ${object.toString()}`)
    }

    const deleteItem = () => {
      isDialogVisible.value = true
      store.dispatch('device/deleteDeviceById', item.value.id).then(response => {
        isDialogVisible.value = false
        dialogDelete.value = false
        notify(true, true, response.message)
      }).catch(error => {
        isDialogVisible.value = false
        dialogDelete.value = false
        notify(false, true, error.response.data.message)
      })
    }

    return {
      refreshComponent,
      deleteItem,
      messageDialog,
      removeDevice,
      showToken,
      contentToken,
      showDialogContent,
      filterElts,
      getEltsPerPage,
      getElts,
      closeDialog,
      openItem,
      formattingDate,
      loading,
      currentPage,
      lastPage,
      dialog,
      dialogDelete,
      isDialogVisible,
      isDialogVisible2,
      isActive: [
        { text: 'Tous', value: null },
        { text: 'Oui', value: true },
        { text: 'Non', value: false },
      ],
      headers: [
        { text: 'APPAREIL', value: 'agent', sortable: false },
        { text: 'ACTIF', value: 'is_active', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      itemsPerPage: parseInt(store.state.device.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
