<template>
  <div>
    <loader-dialog :is-dialog-visible="loaderDialog" />
    <v-card>
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ reponseMsg }}
      </v-alert>
      <v-img
        v-if="license_path"
        :src="license_path"
        height="250"
        contain
      />
      <v-img
        v-else
        src="@/assets/images/png/mboa_cov_logo.png"
        height="250"
        contain
      />
      <v-card-title>
        Permis de conduire
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Validé le:</span>
            <span class="text--secondary font-weight-bold">{{ validated_at ? formattingDate(validated_at) : null }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-tooltip
          left
          color="primary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="primary mr-2"
              icon
              elevation="5"
              v-on="on"
              @click="dialogConfirm = true"
            >
              <v-icon
                dense
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-btn>
          </template>
          <span>Valider</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="error"
        >
          <template #activator="{on, attr}">
            <v-btn
              v-bind="attr"
              class="error mr-2"
              icon
              elevation="5"
              v-on="on"
              @click="rejectConfirm = true"
            >
              <v-icon
                dense
              >
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <span>Rejeter</span>
        </v-tooltip>

        <v-tooltip
          right
          color="grey"
        >
          <template #activator="{ attr, on }">
            <v-btn
              v-bind="attr"
              icon
              elevation="5"
              class="grey"
              outlined
              v-on="on"
              @click="openDialog"
            >
              <v-icon
                dense
              >
                {{ icons.mdiMagnifyPlusOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Agrandir</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <license-img
      :license_path="license_path"
      :dialog="dialog"
      @clickout="closeDialog"
    />
    <confirm-dialog
      :message="message"
      :dialog-delete="dialogConfirm"
      @clickout="dialogConfirm = false"
      @submitted="validateLicense"
    />
    <reject-license-form
      :dialog="rejectConfirm"
      :user="user"
      @clickout="rejectConfirm = false"
    />
  </div>
</template>

<script>
import { mdiCheck, mdiMagnifyPlusOutline, mdiClose } from '@mdi/js'
import LicenseImg from '@core/components/user/LicenseImg'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { formatDate, formatStartDate } from '@core/date/dateFunctions'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import RejectLicenseForm from '@core/components/user/RejectLicenseForm'
import store from '@/store'

export default {
  name: 'LicenseCard',
  components: {
    RejectLicenseForm, LoaderDialog, ConfirmDialog, LicenseImg,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    license_path: {
      type: String,
      default: '@/assets/images/png/mboa_cov_logo.png',
    },
    // eslint-disable-next-line vue/prop-name-casing
    validated_at: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      icons: {
        mdiCheck,
        mdiMagnifyPlusOutline,
        mdiClose,
      },
      dialog: false,
      dialogConfirm: false,
      rejectConfirm: false,
      loaderDialog: false,
      isAlertVisible: false,
      error: false,
      message: 'Voulez-vous valider le permis de conduire?',
      reponseMsg: null,
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    validateLicense() {
      this.loaderDialog = true
      store.dispatch('user/validateLicenseDrive', this.user).then(response => {
        this.reponseMsg = response.message
        this.loaderDialog = false
        this.dialogConfirm = false
        this.error = false
        this.isAlertVisible = true
        notify(true, true, response.message)
      }).catch(error => {
        this.reponseMsg = error.response.data.message
        this.loaderDialog = false
        this.dialogConfirm = false
        this.error = true
        this.isAlertVisible = true
        notify(false, true, error.response.data.message)
      })
    },
  },
}
</script>

<style scoped>

</style>
