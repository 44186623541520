var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader-dialog',{attrs:{"is-dialog-visible":_vm.isDialogVisible2}}),_c('v-card',{staticClass:"mb-8"},[_c('div',{staticClass:"m-2"},[_c('v-row',{staticClass:"ml-5 mr-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Rechercher par le nom de l'appareil","outlined":"","dense":"","placeholder":"Rechercher par le nom de l'appareil","hide-details":"","clearable":""},on:{"input":_vm.filterElts},model:{value:(_vm.$store.state.device.field.agent),callback:function ($$v) {_vm.$set(_vm.$store.state.device.field, "agent", $$v)},expression:"$store.state.device.field.agent"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.isActive,"label":"Actif?","outlined":"","dense":""},on:{"input":_vm.filterElts},model:{value:(_vm.$store.state.device.field.is_active),callback:function ($$v) {_vm.$set(_vm.$store.state.device.field, "is_active", $$v)},expression:"$store.state.device.field.is_active"}})],1)],1)],1)]),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.isDialogVisible,"loading-text":"Chargement...","headers":_vm.headers,"items":_vm.$store.getters['device/devices'],"items-per-page":_vm.$store.getters['device/per_page'],"page":_vm.$store.getters['device/current_page'],"dense":"","fixed-header":"","height":"500","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.$store.getters, 'device/current_page', $event)},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":item.is_active ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(item.is_active ? "OUI" : "NON")+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.created_at))))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.updated_at))))+" ")]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted_at ? _vm.formattingDate(("" + (item.deleted_at))) : null)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('buttons-group',{attrs:{"item":item,"remove":true,"edit":false,"lock":false,"status":false},on:{"view":function($event){return _vm.showToken(item.key)},"remove":_vm.removeDevice}})]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-text-field',{attrs:{"value":_vm.$store.state.device.field.paginate,"label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getEltsPerPage}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getElts},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('device-token-dialog',{attrs:{"token":_vm.contentToken,"dialog-content":_vm.showDialogContent},on:{"clickout":function($event){_vm.showDialogContent = false}}}),_c('confirm-dialog',{attrs:{"message":_vm.messageDialog,"dialog-delete":_vm.dialogDelete},on:{"clickout":function($event){_vm.dialogDelete = false},"submitted":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }