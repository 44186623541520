<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
    >
      <v-card>
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="car.photo_path ? '' : 'primary'"
            :class="car.photo_path ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="car.photo_path"
              :src="car.photo_path"
              contain
            ></v-img>
            <v-img
              v-else
              src="@/assets/images/png/mboa_cov_logo.png"
              contain
            />
          </v-avatar>
        </v-card-title>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Marque:</span>
              <span class="text--secondary font-weight-bold">{{ car.marque }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Model:</span>
              <span class="text--secondary font-weight-bold">{{ car.model }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Année de sortie:</span>
              <span class="text--secondary font-weight-bold">{{ car.release_year }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Couleur:</span>
              <span class="text--secondary font-weight-bold">{{ car.color }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Musique:</span>
              <v-chip
                small
                :color="car.music ? 'primary' : 'error'"
                :class="`${ car.music ? 'primary' : 'error'}--text`"
                class="v-chip-light-bg"
              >
                {{ car.music === true ? 'OUI' : 'NON' }}
              </v-chip>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Climatisation:</span>
              <v-chip
                small
                :color="car.air_conditioner ? 'primary' : 'error'"
                :class="`${ car.air_conditioner ? 'primary' : 'error'}--text`"
                class="v-chip-light-bg"
              >
                {{ car.air_conditioner === true ? 'OUI' : 'NON' }}
              </v-chip>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Ajouté le:</span>
              <span class="text--secondary font-weight-bold">{{ car.created_at ? new Date(car.created_at).toLocaleString() : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Modifié le:</span>
              <span class="text--secondary font-weight-bold">{{ car.updated_at ? new Date(car.updated_at).toLocaleString() : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Supprimé le:</span>
              <span class="text--secondary font-weight-bold">{{ car.deleted_at ? new Date(car.deleted_at).toLocaleString() : null }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CarDetailsDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('car', ['car']),
  },

  methods: {
    close() {
      this.$emit('clickout')
    },
  },
}
</script>

<style scoped>

</style>
