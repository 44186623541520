<template>
  <div>
    <loader-dialog :is-dialog-visible="loaderDialog" />
    <v-card>
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ reponseMsg }}
      </v-alert>
      <v-card-text class="d-flex ">
        <v-img
          v-if="doc.image_1"
          :src="doc.image_1"
          width="250"
          cover
          class="mx-2"
        >
          <v-tooltip
            right
            color="grey"
          >
            <template #activator="{ attr, on }">
              <v-btn
                v-bind="attr"
                icon
                elevation="5"
                class="info"
                v-on="on"
                @click="openDialog(doc.image_1)"
              >
                <v-icon
                  dense
                >
                  {{ icons.mdiMagnifyPlusOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Agrandir</span>
          </v-tooltip>
        </v-img>
        <v-img
          v-else
          src="@/assets/images/png/mboa_cov_logo.png"
          height="250"
          contain
        />
        <v-img
          v-if="doc.image_2"
          :src="doc.image_2"
          width="250"
          cover
          class="mx-2"

        >
          <v-tooltip
            right
            color="grey"
          >
            <template #activator="{ attr, on }">
              <v-btn
                v-bind="attr"
                icon
                elevation="5"
                class="info"
                v-on="on"
                @click="openDialog(doc.image_2)"
              >
                <v-icon
                  dense
                >
                  {{ icons.mdiMagnifyPlusOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Agrandir</span>
          </v-tooltip>
        </v-img>
      </v-card-text>
      <v-card-title>
        {{ doc.type == "id_card"?"CNI": 'Permis de conduire'}}
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Validé le:</span>
            <span class="text--secondary font-weight-bold">{{ doc.processed_at ? formattingDate(doc.processed_at) : null }}</span>
          </v-list-item>
          <div v-if="doc.comment && doc.comment.length"
            class="px-0 mb-n2"
          >
            <div class="font-weight-medium me-2">Commentaire:</div>
            <p class="text--secondary font-weight-bold" v-html="">{{ doc.comment }}</p>
          </div>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-tooltip
          left
          color="primary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="primary mr-2"
              icon
              elevation="5"
              v-on="on"
              @click="dialogConfirm = true"
            >
              <v-icon
                dense
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-btn>
          </template>
          <span>Valider</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="error"
        >
          <template #activator="{on, attr}">
            <v-btn
              v-bind="attr"
              class="error mr-2"
              icon
              elevation="5"
              v-on="on"
              @click="rejectConfirm = true"
            >
              <v-icon
                dense
              >
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <span>Rejeter</span>
        </v-tooltip>


      </v-card-actions>
    </v-card>
    <license-img
      :license_path="imageUrl"
      :dialog="dialog"
      @clickout="closeDialog"
    />
    <confirm-dialog
      :message="message"
      :dialog-delete="dialogConfirm"
      @clickout="dialogConfirm = false"
      @submitted="validateLicense"
    />
    <reject-license-form
      :dialog="rejectConfirm"
      :user="user"
      :doc="doc"
      @clickout="rejectConfirm = false"
    />
  </div>
</template>

<script>
import { mdiCheck, mdiMagnifyPlusOutline, mdiClose } from '@mdi/js'
import LicenseImg from '@core/components/user/LicenseImg'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { formatDate, formatStartDate } from '@core/date/dateFunctions'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import RejectLicenseForm from '@core/components/user/RejectLicenseForm'
import store from '@/store'

export default {
  name: 'DocumentCard',
  components: {
    RejectLicenseForm, LoaderDialog, ConfirmDialog, LicenseImg,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    doc: {
      type: Object,
      default: ()=> { return {image_1: '@/assets/images/png/mboa_cov_logo.png',id: null,user_id: null}}
    },
  },

  data() {
    return {
      icons: {
        mdiCheck,
        mdiMagnifyPlusOutline,
        mdiClose,
      },
      dialog: false,
      dialogConfirm: false,
      rejectConfirm: false,
      loaderDialog: false,
      isAlertVisible: false,
      error: false,
      message: 'Voulez-vous valider le permis de conduire?',
      reponseMsg: null,
      imageUrl:  '@/assets/images/png/mboa_cov_logo.png'
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    openDialog(url) {
      this.imageUrl =  url
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    validateLicense() {
      this.loaderDialog = true
      store.dispatch('user/validateLicenseDrive', { ...this.user,doc: this.doc.id }).then(response => {
        this.reponseMsg = response.message
        this.loaderDialog = false
        this.dialogConfirm = false
        this.error = false
        this.isAlertVisible = true
        notify(true, true, response.message)
      }).catch(error => {
        this.reponseMsg = error.response.data.message
        this.loaderDialog = false
        this.dialogConfirm = false
        this.error = true
        this.isAlertVisible = true
        notify(false, true, error.response.data.message)
      })
    },
  },
}
</script>

<style scoped>

</style>
