<template>
  <div>
    <loader-dialog :is-dialog-visible="isDialogVisible2" />
    <v-card class="mb-8">
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="$store.state.car.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details
              clearable
              @input="filterElts"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['car/cars']"
        :items-per-page="$store.getters['car/per_page']"
        :page.sync="$store.getters['car/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-tooltip
            bottom
            color="primary"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                @click="openItem(item)"
                v-on="on"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Consulter</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.car.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getEltsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getElts"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <car-details-dialog
      :dialog="dialog"
      @clickout="closeDialog"
    />
  </div>
</template>

<script>
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import CarDetailsDialog from '@core/components/user/CarDetailsDialog'
import { formatDate } from '@core/date/dateFunctions'
import { ref, onMounted, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'UserCarList',
  // eslint-disable-next-line vue/no-unused-components
  components: { CarDetailsDialog, LoaderDialog, ConfirmDialog },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    user_id: {
      type: String,
      default: null,
    },

    isRefreshed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }

    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const isDialogVisible2 = ref(false)
    const dialog = ref(false)

    const formattingDate = date => formatDate(date)

    // eslint-disable-next-line no-unused-vars
    const openItem = item => {
      isDialogVisible2.value = true
      store.dispatch('car/getCarById', item).then(() => {
        isDialogVisible2.value = false
      })
      dialog.value = true
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const getElts = (page = 1) => {
      isDialogVisible.value = true
      store.state.car.field.user_id = props.user_id
      store.dispatch('car/getCarsList', { page, field: store.state.car.field }).then(() => {
        currentPage.value = store.getters['car/current_page']
        lastPage.value = store.getters['car/last_page']
        isDialogVisible.value = false
      })
    }

    const filterElts = () => {
      setTimeout(() => {
        getElts()
      }, 1000)
    }

    // eslint-disable-next-line camelcase
    const getEltsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.car.field.paginate = per_page
        store.state.car.field.user_id = props.user_id
        store.dispatch('car/getCarsList', {
          page: store.getters['car/current_page'],
          field: store.state.car.field,
        }).then(() => {
          currentPage.value = store.getters['car/current_page']
          lastPage.value = store.getters['car/last_page']
          isDialogVisible.value = false
        })
      }
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    watch(() => props.isRefreshed, (val1, val2) => {
      if (val1 === true && val2 === false) getElts()
    })

    onMounted(() => {
      getElts(store.getters['car/current_page'])
    })

    return {
      filterElts,
      openItem,
      formattingDate,
      closeDialog,
      loading,
      getEltsPerPage,
      getElts,
      currentPage,
      lastPage,
      dialog,
      dialogDelete,
      isDialogVisible,
      isDialogVisible2,
      headers: [
        { text: 'MARQUE', value: 'marque' },
        { text: 'MODEL', value: 'model' },
        { text: 'ANNEE DE SORTIE', value: 'release_year' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: 10,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
