<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      @click:outside="close"
    >
      <v-card>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-slider
            v-model="width"
            class="align-self-stretch"
            min="200"
            max="1000"
            step="1"
          ></v-slider>
          <v-img
            v-if="license_path"
            :aspect-ratio="16/9"
            :width="width"
            :src="license_path"
            contain
          ></v-img>
          <v-img
            v-else
            :aspect-ratio="16/9"
            :width="width"
            contain
            src="@/assets/images/png/mboa_cov_logo.png"
          ></v-img>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'LicenseImg',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    license_path: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    width: 300,
  }),

  methods: {
    close() {
      this.$emit('clickout')
    },
  },
}
</script>

<style scoped>

</style>
